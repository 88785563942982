























































/* eslint-disable no-invalid-this */
import Vue from 'vue'
import { debounce } from 'debounce'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'
import WalletCard from '@views/dashboard/WalletCard.vue'
import InputInTwoDecimals from '@components/input/currency-input.vue'
import ConfirmModal from '@components/modal/continue-or-cancel-modal.vue'
import Flash from '@components/modal/slide-down-flash.vue'
// @ts-ignore
import corpDetailsMixin from '@utils/mixins/addy-plus-corporation-details-from-side-menu-mixin.js'
import { fetchWalletDetails, fetchInvestedMembers, transferFunds } from '@api/addy-plus/wallet'
import { IData, IMethods, IComputed } from '@/types/addy_plus/corporations/wallet/TransferFunds'

export default Vue.extend<IData, IMethods, IComputed>({
  mixins: [corpDetailsMixin],
  components: {
    Breadcrumb,
    WalletCard,
    InputInTwoDecimals,
    ConfirmModal,
    Flash,
  },
  data() {
    return {
      walletBalance: 0,
      processingDepositTotal: 0,
      investor: null,
      searchQuery: '',
      amount: null,
      investors: [],
      isFetchingInvestors: false,
      showConfirmModal: false,
      showTransferFlash: false,
      timer: null,
    }
  },
  computed: {
    pageTitle() {
      return this.$route.meta.breadcrumb
    },
    walletCardData() {
      return {
        walletBalance: this.walletBalance,
        processingDepositTotal: this.processingDepositTotal,
      }
    },
    modalText() {
      return `Please confirm that you would like to send $${this.amount} to ${this.investor?.name}.`
    },
    flashText() {
      return `$${this.amount} transferred to ${this.investor?.name}`
    },
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
    exceedAvailableFunds() {
      return !!this.amount && (this.amount > this.walletBalance)
    },
  },
  created() {
    this.fetchWalletBalance()
  },
  methods: {
    fetchWalletBalance() {
      fetchWalletDetails(this.corporationId).then((res) => {
        if (!res.success) return
        const { pendingCoinOrderAmount, walletBalance } = res.data
        this.walletBalance = walletBalance as number
        this.processingDepositTotal = pendingCoinOrderAmount as number
      })
    },
    onInvestorSearch(value) {
      this.searchQuery = value
      this.onDebounceSearch()
    },
    onDebounceSearch: debounce(function(this: IData) {
      this.investor = null
      this.isFetchingInvestors = true
      // @ts-ignore
      fetchInvestedMembers(this.corporationId, this.searchQuery).then((res) => {
        if (!res.success) return

        this.investors = res.data.members || []
        this.isFetchingInvestors = false
      })
    }, 1000),
    onInput(value) {
      this.amount = value
    },
    onFundsTransfer() {
      const payload = {
        amount: this.amount || 0,
        hashid: this.investor?.hashid || '',
      }
      transferFunds(this.corporationId, payload).then((res) => {
        if (!res.success) return

        this.showConfirmModal = false
        this.showSuccessFlash()
      })
    },
    showSuccessFlash() {
      this.fetchWalletBalance()
      this.showTransferFlash = true
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hideSuccessFlash()
      }, 4000)
    },
    hideSuccessFlash() {
      this.showTransferFlash = false
      this.resetPage()
      this.toWalletIndexPage()
    },
    resetPage() {
      this.investor = null
      this.amount = null
    },
    toWalletIndexPage() {
      this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/wallet`)
    },
  },
})
